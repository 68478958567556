import { Col, Row } from 'antd'
import { EmplacementDetail } from '../../../../../models'
import SectionLayout from '../SectionLayout'
import { CInput } from '../Wrapper'

const EmplacementInfo = (props: { initData?: EmplacementDetail }) => {
  const { initData } = props

  return (
    <>
      <SectionLayout title="Emplacement précédent">
        <Row>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 8 }} className="form-label">
                Entrepôt
              </Col>
              <Col lg={21} xl={{ span: 14, offset: 1 }}>
                <CInput
                  value={initData?.pre_warehouse_code_nom}
                  className="custom-input"
                  disabled
                  id="empl-pre-warehouse"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Zone
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.pre_zone_code || '-'}
                  className="custom-input"
                  disabled
                  id="empl-pre-zone"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Travée
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.pre_location_bay || '-'}
                  className="custom-input"
                  disabled
                  id="empl-pre-bay"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Allée
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.pre_location_aisle || '-'}
                  className="custom-input"
                  disabled
                  id="empl-pre-aisle"
                />
              </Col>
            </Row>
          </Col>
          {(initData?.pre_is_visible_level || !initData?.pre_level) && (
            <Col lg={24} xl={{ span: 4 }}>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 6 }} className="form-label">
                  Niveau
                </Col>
                <Col lg={21} xl={{ span: 10, offset: 1 }}>
                  <CInput
                    value={
                      !initData?.pre_level
                        ? initData?.cur_position_code?.[1] || '-'
                        : initData?.pre_level === -1
                        ? '0'
                        : initData?.pre_level
                    }
                    className="custom-input"
                    disabled
                    id="empl-pre-level"
                  />
                </Col>
              </Row>
            </Col>
          )}
          {(initData?.pre_is_visible_position ||
            !initData?.pre_position_code) && (
            <Col lg={24} xl={{ span: 4 }}>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 8 }} className="form-label">
                  Position
                </Col>
                <Col lg={21} xl={{ span: 10, offset: 1 }}>
                  <CInput
                    value={initData?.pre_position_code?.[0] || '-'}
                    className="custom-input"
                    disabled
                    id="empl-pre-position"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </SectionLayout>
      <SectionLayout title="Emplacement actuel">
        <Row>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 8 }} className="form-label">
                Entrepôt
              </Col>
              <Col lg={21} xl={{ span: 14, offset: 1 }}>
                <CInput
                  value={initData?.cur_warehouse_code_nom}
                  className="custom-input"
                  disabled
                  id="empl-cur-warehouse"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Zone
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.cur_zone_code}
                  className="custom-input"
                  disabled
                  id="empl-cur-zone"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Travée
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.cur_location_bay}
                  className="custom-input"
                  disabled
                  id="empl-cur-bay"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={24} xl={{ span: 4 }}>
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                Allée
              </Col>
              <Col lg={21} xl={{ span: 10, offset: 1 }}>
                <CInput
                  value={initData?.cur_location_aisle}
                  className="custom-input"
                  disabled
                  id="empl-cur-aisle"
                />
              </Col>
            </Row>
          </Col>
          {initData?.cur_is_visible_level && (
            <Col lg={24} xl={{ span: 4 }}>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 6 }} className="form-label">
                  Niveau
                </Col>
                <Col lg={21} xl={{ span: 10, offset: 1 }}>
                  <CInput
                    value={
                      !initData.cur_level
                        ? initData.cur_position_code?.[1]
                        : initData?.cur_level === -1
                        ? '0'
                        : initData?.cur_level
                    }
                    className="custom-input"
                    disabled
                    id="empl-cur-level"
                  />
                </Col>
              </Row>
            </Col>
          )}
          {initData?.cur_is_visible_position && (
            <Col lg={24} xl={{ span: 4 }}>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 8 }} className="form-label">
                  Position
                </Col>
                <Col lg={21} xl={{ span: 10, offset: 1 }}>
                  <CInput
                    value={initData.cur_position_code?.[0]}
                    className="custom-input"
                    disabled
                    id="empl-cur-position"
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </SectionLayout>
    </>
  )
}

export default EmplacementInfo
