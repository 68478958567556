import { EmplacementResponse } from '../models'
import baseAxios from './clientAPI'

const emplacementApi = {
  getEmplacementByStockId(stockId: string): Promise<EmplacementResponse> {
    const url = `warehouse/stock/emplacement?stock_id=${stockId}`
    return baseAxios.get(url)
  },
}

export default emplacementApi
