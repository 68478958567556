import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import emplacementApi from '../../../../http/emplacementApi'
import { EmplacementDetail } from '../../../../models'

const useEmplacementHook = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const stockId = searchParams.get('stock-id')
  const navigate = useNavigate()
  const [emplacementData, setEmplacementData] = useState<EmplacementDetail>()

  const onCancel = () => {
    navigate(
      `/gestion-de-stock/stock?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const fetchEmplacementData = async () => {
    if (!stockId) return
    setIsLoading(true)
    try {
      const response = await emplacementApi.getEmplacementByStockId(stockId)
      setEmplacementData(response.data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      onCancel()
    }
  }

  useEffect(() => {
    fetchEmplacementData()
  }, [stockId])

  return {
    onCancel,
    isLoading,
    emplacementData,
  }
}

export default useEmplacementHook
