import { Col, Modal, Row, Spin } from 'antd'
import useEmplacementHook from '../../hook/useEmplacementHook'
import EmplacementInfo from '../EmplacementDetail/EmplacementInfo'

const EmplacementPopup = () => {
  const { onCancel, isLoading, emplacementData } = useEmplacementHook()

  return (
    <Modal
      prefixCls="modal-new-stock"
      centered
      open={true}
      onCancel={onCancel}
      width={'90%'}
      className="border-solid border-secondary rounded-xl"
      footer={null}
      destroyOnClose={true}
    >
      <Spin spinning={isLoading} style={{ overflow: 'auto' }}>
        <Row className="bd-bot-page-title pb-3.5" style={{ height: '8%' }}>
          <Col className="flex items-center">
            <div className="flex items-baseline">
              <span className="modal-title-heading-1 mr-2">Emplacement</span>
              <span className=" modal-title-heading-2 normal-case">
                (article n° {emplacementData?.sscc})
              </span>
            </div>
          </Col>
        </Row>
        <div className="gap-9"></div>
        <div className="overflow-y-auto main-content-mission ">
          <EmplacementInfo initData={emplacementData} />
        </div>
      </Spin>
    </Modal>
  )
}

export default EmplacementPopup
